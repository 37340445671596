import { Popup } from 'Lib/popup/popup';
import { eventSender } from 'Lib/event-sender/event-sender';

function setEventPopup() {
    const popup = new Popup('popup', document.querySelector('.popup-sale__popup'));
    const elsPopup = document.querySelectorAll('.popup-sale__btn');

    elsPopup?.forEach((item) => {
        item.addEventListener('click', () => {
            popup.open();

            eventSender.customDL({
                event: 'eksmo',
                eventAction: 'click',
                eventCategory: $('title').text().trim(),
                eventLabel: 'Попап акции ко дню книгодарения',
            });
        });
    });
}

setEventPopup();
$('body').on('footerLoaded.eksmo', setEventPopup);